<template>
  <div class="p-4">
    <h4 class="mb-2">
      Rückmeldung/Reklamation
    </h4>
    <p>
      In deiner Lieferung fehlt etwas? Du hast Produkte in der Lieferungen die du beanstanden musst? Dann füll einfach dieses Formular aus und wir bearbeiten deine Rückmeldung umgehend. 
    </p>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Email"
            label-for="v-email"
          >
            <b-form-input
              v-model="email"
              id="v-email"
              type="email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Bitte Bestellnummer angeben (R......) Um diese Bestellung handelt es sich"
            label-for="v-order-no"
          >
            <b-form-input
              v-model="orderNo"
              id="v-order-no"
              placeholder="Bestellnummer"
              @change="get_products"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Bitte Name des/r Besteller: in angeben: Vorname/ Name"
            label-for="v-customer-name"
          >
            <b-form-input
              v-model="customerName"
              id="v-customer-name"
              placeholder="Besteller"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Folgende Produkte fehlen in meiner Lieferung"
            label-for="v-products-miss"
          >
            <v-select
              multiple
              v-model="selectedMissingProducts"
              label="name"
              :options="orderProducts"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Ich habe Beanstandungen an folgende Produkte in der Lieferung"
            label-for="v-products-complaints"
          >
            <v-select
              multiple
              v-model="selectedProductsComplaints"
              label="name"
              :options="orderProducts"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Weitere Anmerkungen"
            label-for="v-remarks"
          >
            <b-form-input
              v-model="remarks"
              id="v-remarks"
              placeholder="Anmerkungen"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="submit"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import vSelect from "vue-select"
import Ripple from 'vue-ripple-directive'
import axios from "axios"

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      email: "",
      orderNo: "",
      customerName: "",
      selectedMissingProducts: [],
      selectedProductsComplaints: [],
      remarks: "",
      orderProducts: [],
    }
  },
  methods: {
    get_products() {
      if (this.orderNo.match(/R\d{9}/)) {
        axios
          .get(process.env.VUE_APP_ROOT_API + "/order-products/", {
            params: {order_no: this.orderNo}
          })
          .then((response) => {
            this.orderProducts = response.data.order_products.map(item => {
              return { name: item.product_name + " " + item.unit_to_display }
            });
          })
      }
    },
    submit() {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/invoice-corrections-form/", {
          email: this.email,
          order_no: this.orderNo,
          customer_name: this.customerName,
          missing_products: this.selectedMissingProducts,
          products_complaints: this.selectedProductsComplaints,
          remarks: this.remarks
        })
        .then((response) => {
          console.log(response)
          this.email = ""
          this.orderNo = ""
          this.customerName = ""
          this.selectedMissingProducts = []
          this.selectedProductsComplaints = []
          this.remarks = ""
        })
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
